<template>
    <div class="container">
        <div class="commonList">
            
            <el-input class="commonSearch" placeholder="根据用户ID搜索" v-model="searchKey" clearable>
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>

            <table v-show="userList.length>0" v-loading="isLoading">
                <thead>
                    <tr>
                        <td>用户ID</td>
                        <td>默认服务器ID</td>
                        <td>创建时间</td>
                        <td>更新时间</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in userList" :key="index">
                        <td><a :href="'https://www.sidoc.cn/user/' + item.user_id" target="_blank">{{item.user_id}}</a></td>
                        <td>{{item.default_server_id}}</td>
                        <td>{{item.create_time}}</td>
                        <td>{{item.update_time}}</td>
                    </tr>
                </tbody>
            </table>

            <el-pagination
                background
                @current-change="loadData"
                :current-page.sync="currentPage"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="total"
                style="text-align: right;margin-top: 10px;">
            </el-pagination>
        </div>

    </div>
    
</template>
<script>

import { frpPost,frpUserListPost } from '@/service/api'

export default {
    data(){
        return {
            userList:[],

            pageSize:18,
            currentPage:1,
            total:0,
            searchKey:"",
            isLoading:false,
        }
    },
    mounted(){
        this.loadData(1);
    },
    methods:{
        loadData(currentPage){
            let that = this;
            this.isLoading = true;
            let params = {
                "currentPage":currentPage,
                "pageSize":this.pageSize,
                "searchKey":this.searchKey
            }
            this.$api.request({
                url: process.env.VUE_APP_BASE_URL_FRP_CENTER + '/userList',
                data:params,
                method: 'post',
            }).then(res => {
                if(res.code == 0){
                    this.userList = res.data.list;
                    this.total = res.data.count;
                }
            }).finally(()=>{
               that.isLoading = false;
            });
        }
    },
    watch:{
        searchKey(){
            this.currentPage = 1;
            this.loadData(this.currentPage);
        }
    }
}
</script>
